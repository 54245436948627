import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    display: flex;
    background-color: #F5F5F5;
    z-index: 100;

    @media screen and (max-width: 992px) {
        top: unset;
        bottom: 0;
    }
`
export const AddressInput = styled.input`
    flex: 1;
    padding: 5px 20px;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 0.9rem;
`

export const ConfirmButton = styled.button`
    font-size: 0.8rem;
    padding: 2px 5px;
    border: none;
    background-color: transparent;
    outline: none;
`