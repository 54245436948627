import React, {useEffect, useState} from 'react'
import { useLocation, useHistory  } from 'react-router-dom'
import { useWallet } from '../../providers/Wallet'
import { Container, AddressInput, ConfirmButton } from './style'

const ViewAsUser = ({sidebarWidth}) => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [viewAsAddress, setViewAsAddress] = useState("");
    const { setAccount, connected } = useWallet();

    // connect to new wallet from input
    const connect = () => {
        setAccount(viewAsAddress);
        params.set('address', viewAsAddress);
        history.replace(`${location.pathname}?${params.toString()}`);
    }

    //on wallect connect
    useEffect(() => {
        if(connected){
            const address = params.get("address");
            if(address){
                setViewAsAddress(address);
                setAccount(address);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected])

    //keep saved viewAsAddress on route change
    useEffect(() => {
        if(viewAsAddress && !params.has('address')){
            params.set('address', viewAsAddress);
            history.replace(`${location.pathname}?${params.toString()}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return(
        <Container sidebarWidth={sidebarWidth}>
            <AddressInput
                placeholder={connected ? '[enter wallet address]' : 'connect your wallet first'}
                value={viewAsAddress}
                onInput={e => setViewAsAddress(e.target.value)}
                disabled={!connected}
            />
            <ConfirmButton onClick={connect} disabled={!connected}>
                Confirm
            </ConfirmButton>
        </Container>
    )
}

export default ViewAsUser
